<template>
    <div class="container my-4">
        <div class="card">
            <div class="card-header">Default package dimensions</div>
            <div class="card-body">
                <div v-if="hasPermissions('modify:settings:package_dimensions')" class="d-flex align-items-center">
                    <div>Add default package dimensions</div>
                    <div class="ml-auto">
                        <form class="d-flex" @submit.prevent="addPackage">
                            <input type="number"
                                   max="150"
                                   min="1"
                                   step="1"
                                   placeholder="Length"
                                   class="form-control form-control-sm"
                                   v-model="dimensions.length"
                                   required />
                            <input type="number"
                                   max="150"
                                   min="1"
                                   step="1"
                                   placeholder="Width"
                                   class="form-control form-control-sm"
                                   v-model="dimensions.width"
                                   required />
                            <input type="number"
                                   max="150"
                                   min="1"
                                   step="1"
                                   placeholder="Height"
                                   class="form-control form-control-sm"
                                   v-model="dimensions.height"
                                   required />
                            <button class="add-dimensions-btn btn btn-sm btn-light">Add</button>
                        </form>
                    </div>
                </div>
                <div v-if="packages.length">
                    <hr class="my-3" v-if="hasPermissions('modify:settings:package_dimensions')">
                    <div class="d-flex flex-wrap">
                        <div class="dimensions-box my-3 px-3 py-1 mr-3" v-for="pkg in packages" :key="pkg.id">
                            <div>{{ pkg.display_name }}</div>
                            <div v-if="hasPermissions('modify:settings:package_dimensions')"
                                 @click="deletePackage(pkg.id)">
                                <i class="fa fa-times"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getDefaultDimensions,
        addPackageDimensions,
        deletePackageDimensions
    } from '../../services/ShipmentService';
    import {hasPermissions} from '../../helpers/permissionUtils';

    export default {
        name: 'PackageDimensions',
        data() {
            return {
                packages: [],
                dimensions: {
                    length: '',
                    width: '',
                    height: '',
                    unit: 'inch'
                }
            };
        },
        created() {
            this.getPackages();
        },
        methods: {
            async getPackages() {
                try {
                    const response = await getDefaultDimensions();
                    this.packages = response.data;
                } catch (error) {
                    console.log(error);
                }
            },
            async addPackage() {
                try {
                    const response = await addPackageDimensions(this.dimensions);
                    this.packages.push(response.data);
                } catch (error) {
                    Object.keys(this.dimensions).forEach(key => (this.dimensions[key] = ''));
                    this.dimensions.unit = 'inch';
                }
            },
            async deletePackage(id) {
                try {
                    await deletePackageDimensions(id);
                    this.packages = this.packages.filter(p => p.id !== id);
                } catch (error) {
                    console.log(error);
                }
            },
            hasPermissions,
        }
    };
</script>

<style scoped>
    input {
        width: 95px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    input:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }

    .add-dimensions-btn {
        width: 75px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid #becad6;
        border-left: none;
    }

    .dimensions-box {
        width: 145px;
        border: 1px solid rgb(175, 175, 175);
        border-radius: 4px;
        position: relative;
    }

    .fa-times {
        color: rgb(131, 131, 131);
        cursor: pointer;
        font-size: 16px;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
</style>
